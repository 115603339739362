// Note: If you change the url, also change them from gatsby-node.js

const pageUrls = {
  ABOUT_URL : '/about',
  ANNIVERSARY_SHARE_ON_FACEBOOK_URL : `https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fgloryreborn.org/anniversary`,
  ANNIVERSARY_SHARE_ON_TWITTER_URL : `https://twitter.com/intent/tweet?text=Hello%20Friends!%20Let's%20celebrate%20health%20%26%20hope%20by%20tagging%2018%20friends%20and%20donating%20%2418%20to%20help%20moms%20and%20babies!&url=https%3A%2F%2Fgloryreborn.org%2Fanniversary`,
  BLOG_URL : '/blog/',
  DONATE_URL : '/donate',
  GIVING_TUESDAY_URL : '/givingtuesday2024',
  GLORY_REBORN_FACEBOOK_URL : 'https://www.facebook.com/gloryreborn/',
  GLORY_REBORN_MAIL_TO : 'mailto:info@gloryreborn.org',
  GLORY_REBORN_TEL_NUM : 'tel:63324189337',
  HOME_URL : '/home',
  MEET_THE_TEAM_URL : '/meet-the-team',
  NEWSLETTERS_URL : '/newsletters',
  PIN_PAYMENT_DONATE_TO_GLORY_REBORN : 'https://pay.pinpayments.com/qmxv?description=Donate+to+Glory+Reborn',
  PIN_PAYMENT_SPONSOR_A_BABY_URL : 'https://pay.pinpayments.com/qmxv/?amount=450&amp;description=Sponsor+a+Baby+Party',
  PRESS_URL : '/press',
  PRIVACY_POLICY_URL : '/privacy-policy',
  SERVICE_URL : '/services',
  SPONSOR_A_BABY_URL : '/sponsor-a-baby-party',
  SPONSOR_A_MOM_URL : '/sponsor-a-mom',
  TERMS_AND_CONDITIONS_GENERATOR_URL : 'https://termsandconditionstemplate.com/privacy-policy-generator/',
  WOMENS_MONTH_URL : '/womensmonth',
  MOTHERS_DAY_CARD : 'https://mothers.gloryreborn.org/'
};

module.exports = pageUrls;