import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const SubscribeModal = ({
  modalShow,
  onHide,
  heading,
  subheading,
  handleChangeFirstname,
  handleChangeLastname,
  handleChangeEmail,
  handleSubmit
}) => {
  return (
    <Modal
      show={modalShow}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{heading}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{subheading}</p>
        <form onSubmit={handleSubmit}>
          <div>
            <div className="row mt-3">
              <div className="col-md-12">
                <label>First Name</label>
                <input
                  className="form-control"
                  type="text"
                  onChange={handleChangeFirstname}
                  placeholder="First Name"
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-12">
                <label>Last Name</label>
                <input
                  className="form-control"
                  onChange={handleChangeLastname}
                  type="text"
                  placeholder="Last Name"
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-12">
                <label>Email</label>
                <input
                  className="form-control"
                  onChange={handleChangeEmail}
                  type="email"
                  placeholder="Email"
                  required
                />
              </div>
            </div>
          </div>
          <Button type="submit" className="btn btn-purple p-2 mt-4">
            Subscribe to Newsletter
          </Button>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default SubscribeModal;
