import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const NotifModal = ({ id, modalShow, onHide, title, body }) => {
  return (
    <Modal
      id={id}
      show={modalShow}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer>
        <Button className="btn-purple p-2" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default NotifModal;
