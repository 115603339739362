import React, { useState, useEffect } from 'react';
import CookieConsent, { Cookies } from 'react-cookie-consent';
import { graphql, StaticQuery } from 'gatsby';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import Img from 'gatsby-image';
import Link from 'gatsby-link';
import PropTypes from 'prop-types';

import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';

import NotifModal from '../notifmodal';
import SubscribeModal from '../subscribemodal';

import fb from '../../img/icon-blue-fb.svg';
import ig from '../../img/icon-blue-ig.svg';
import logo from '../../img/logo-glory-reborn.png';
import logoColored from '../../img/logo-colored.png';
import logoFooter from '../../img/logo-footer.svg';
import twitter from '../../img/icon-blue-twitter.svg';
import SponsorModal from '../../components/sponsorModal';
import LGLForm from '../../components/LGLForm';

// code syntax-highlighting theme
// feel free to change it to another one
import 'prismjs/themes/prism-twilight.css';

// main site style
import './index.scss';

const pageUrls = require('../../constants');
const cookieName = 'gloryreborn-cookie-consent';

const TemplateWrapper = ({ nav, children, data }) => {
  const [navbarClass, setnavbarClass] = useState('');
  const [navbarType, setnavbarType] = useState('');
  const [logoImg, setLogoImg] = useState(logo);
  const [firstname, setFirstname] = useState();
  const [lastname, setLastname] = useState();
  const [email, setEmail] = useState();
  const [modalShow, setModalShow] = useState(false);
  const [notifModalShow, setNotifModalShow] = useState(false);
  const [sponsorModalShow, setSponsorModalShow] = useState(false);

  const handleChangeFirstname = e => {
    setFirstname(e.target.value);
  };

  const handleChangeLastname = e => {
    setLastname(e.target.value);
  };

  const handleChangeEmail = e => {
    setEmail(e.target.value);
  };

  const clearCookies = ()=> {
    const allCookies = document.cookie.split(';');

    allCookies.forEach(cookie => {
      const cookieName = cookie.split('=')[0].trim();
      Cookies.remove(cookieName, { path: '/', domain: window.location.hostname });
    });
  }


  useEffect(() => {
    const hasConsented = Cookies.get(cookieName);
    const shouldClearCookies = typeof hasConsented === 'undefined' || hasConsented === 'false' || !hasConsented;

    if (shouldClearCookies) {
      clearCookies();
    }
  }, [])

  useEffect(() => {
    // This fetches IP information of user to check if user is from Australia
    fetch('https://ipapi.co/json')
      .then(res => res.json())
      .then(
        result => {
          if (result.country_name == 'Australia') {  
            // Makes "Sponsor a Mom" button link to pin payment for Australian users
            let sponsorAMomBtnsAu = Array.from(document.getElementsByClassName('mom-donate-btn'));
            sponsorAMomBtnsAu.forEach(btn => {
              btn.removeAttribute('data-formid');
              btn.setAttribute(
                'href',
                'https://pay.pinpayments.com/qmxv/subscriptions/new?plan=plan_3du88ey8_Kk8LfVN5nN5qQ'
              );
            });

            // Make "Donate Now" button in navbar link to /donate-australia page if user from Australia
            let donateNowBtnAu = Array.from(document.getElementsByClassName('btn-donate'));
            donateNowBtnAu.forEach(btn => {
              btn.removeAttribute('data-formid');
              btn.classList.add('pin-payment-button');
              btn.setAttribute(
                'href',
                'https://pay.pinpayments.com/qmxv?description=Donate+to+Glory+Reborn'
              );
            });

            let sponsorBabyPartyBtn = Array.from(
              document.getElementsByClassName('btn-sponsor-baby-party')
            );
            sponsorBabyPartyBtn.forEach(btn => {
              btn.removeAttribute('data-formid');
              btn.removeAttribute('role');
              btn.classList.add('pin-payment-button');
              btn.setAttribute(
                'href',
                'https://pay.pinpayments.com/qmxv/?amount=450&amp;description=Sponsor+a+Baby+Party'
              );
            });
          }
        },
        error => {
          console.log(error);
        }
      );

    // This changes navbar to a solid color when scrolled down and back to transparent if on top
    window.addEventListener('scroll', () => {
      if (window.scrollY > 10) {
        setnavbarClass('active');
        setnavbarType('');
        setLogoImg(logo);
      } else {
        setnavbarClass('');
        if (nav === 'colored-nav') {
          setnavbarType('colored-nav');
          setLogoImg(logoColored);
        }
        if (nav === 'fixed-nav') {
          setnavbarType('fixed-nav');
        }
      }
    });

    // This changes navbar to the colored type for specific pages with a colored-nav prop
    if (nav === 'colored-nav') {
      setnavbarType('colored-nav');
      setLogoImg(logoColored);
    }
    if (nav === 'fixed-nav') {
      setnavbarType('fixed-nav');
    }
  }, [nav]);

  // This handles subscription to newsletter when clicking submit
  const handleSubmit = async event => {
    event.preventDefault();
    await addToMailchimp(email, { FNAME: firstname || '', LNAME: lastname || '' });
    setModalShow(false);
    setNotifModalShow(true);
  };

  const handleAcceptCookies = () => {
    const loadGoogleTagManager = () => {
      const isGTMAlreadyLoaded = window.dataLayer;

      if (isGTMAlreadyLoaded) return;

      const script = document.createElement('script');
      script.src = 'https://www.googletagmanager.com/gtm.js?id=GTM-M5CPSJ2';
      document.body.appendChild(script);
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js',
      });
    };

    loadGoogleTagManager();
  };

  const handleOnDecline = () =>  {
    clearCookies();
  }

  return (
    <StaticQuery
      query={pageQuery}
      render={data => (
        <div className="App">
          <CookieConsent
            disableButtonStyles
            enableDeclineButton
            flipButtons
            buttonClasses="btn-reg btn-white"
            buttonStyle={{ height: 'auto', margin: '10px' }}
            buttonText="Accept All"
            cookieName={cookieName}
            declineButtonClasses="btn-reg btn-white"
            declineButtonStyle={{ height: 'auto', margin: '10px' }}
            declineButtonText="Decline All"
            style={{ background: '#987BCC' }}
            onAccept={handleAcceptCookies}
            onDecline={handleOnDecline}
          >
            This website uses cookies to enhance the user experience.{' '}
            <b>
              <Link className="text-white" to="/privacy-policy#cookies">
                Learn More
              </Link>
            </b>
          </CookieConsent>
          <nav
            className={`navbar navbar-fixed-top navbar-dark navbar-expand-lg ${navbarClass} ${navbarType}`}
          >
            <div className="container">
              <Link to="/home" className="navbar-brand">
                <img src={logoImg} alt="Glory Reborn" className="nav-logo" />
              </Link>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="navbar-collapse collapse" id="navbarSupportedContent">
                <ul className="nav navbar-nav ml-auto">
                  <li className="nav-item dropdown">
                    <button
                      className="transparent-btn nav-link dropdown-toggle"
                      id="navbarDropdown"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Get Involved
                    </button>
                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                      <a className="dropdown-item" href={pageUrls.SPONSOR_A_MOM_URL}>
                        Sponsor a Mom
                      </a>
                      <a className="dropdown-item" href={pageUrls.SPONSOR_A_BABY_URL}>
                        Sponsor a Baby Party
                      </a>
                      <a className="dropdown-item" href={pageUrls.DONATE_URL}>
                        Make a Donation
                      </a>
                      <a className="dropdown-item" href={pageUrls.GIVING_TUESDAY_URL}>
                        Giving Tuesday
                      </a>
                      <a className="dropdown-item" onClick={() => setModalShow(true)}>
                        Subscribe
                      </a>
                    </div>
                  </li>
                  <li className="nav-item">
                    <Link to="/blog" className="nav-link">
                      Blog
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to={pageUrls.NEWSLETTERS_URL} className="nav-link">
                      Newsletters
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to={pageUrls.ABOUT_URL} className="nav-link">
                      About
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to={pageUrls.MEET_THE_TEAM_URL} className="nav-link">
                      Meet the Team
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to={pageUrls.SERVICE_URL} className="nav-link">
                      Services
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to={pageUrls.PRESS_URL} className="nav-link">
                      Press
                    </Link>
                  </li>
                  <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-41 nav-item">
                    <SponsorModal
                      id='donate-now-modal'
                      modalShow={sponsorModalShow}
                      onHide={() => {
                        window.location.hash = "";
                        setSponsorModalShow(false);
                        setTimeout(() => {
                          window.location.hash = "sponsorNow";
                        }, 200);
                      }}
                      title="Sponsor Now"
                      leftSide={
                        null
                      }
                      body={
                        sponsorModalShow && <LGLForm formId={"Y7P9fvrQG2r-feneZcSj1g"} initialHeight={767} formPrefix={"https://secure.lglforms.com/form_engine"} />}
                    />
                    <a
                      className="nav-link btn-reg btn-white purple btn-donate"
                      onClick={(e) => {
                        if (!e.currentTarget.href) {
                          setSponsorModalShow(true);
                        }
                      }
                    }>
                      Donate Now
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>

          <div className="pageContent">{children}</div>

          <footer className="site-footer" role="contentinfo">
            <div className="bg-container">
              <div className="container pt-3 pb-3">
                <div className="row">
                  <div className="col-md-3">
                    <img src={logoFooter} alt="" />
                  </div>

                  <div className="col-md-3 footer-link">
                    <div className="ml35">
                      <h4>Link</h4>
                      <p>
                        <a href={pageUrls.HOME_URL}>Home</a>
                      </p>
                      <p>
                        <a href={pageUrls.BLOG_URL}>Blog</a>
                      </p>
                      <p>
                        <a href={pageUrls.NEWSLETTERS_URL}>Newsletters</a>
                      </p>
                      <p>
                        <a href={pageUrls.ABOUT_URL}>About</a>
                      </p>
                      <p>
                        <a href={pageUrls.MEET_THE_TEAM_URL}>Meet the Team</a>
                      </p>
                      <p>
                        <a href={pageUrls.SERVICE_URL}>Services</a>
                      </p>
                      <p>
                        <a href={pageUrls.PRESS_URL}>Press</a>
                      </p>
                      <p>
                        <a href={pageUrls.PRIVACY_POLICY_URL}>Privacy Policy</a>
                      </p>
                    </div>
                  </div>

                  <div className="col-md-3 footer-getinvolved">
                    <div className="ml35">
                      <h4>Get Involved</h4>
                      <p>
                        <a href={pageUrls.SPONSOR_A_MOM_URL}>Sponsor a Mom</a>
                      </p>
                      <p>
                        <a href={pageUrls.SPONSOR_A_BABY_URL}>Sponsor a Baby Party</a>
                      </p>
                      <p>
                        <a href={pageUrls.DONATE_URL}>Make a Donation</a>
                      </p>
                      <p>
                        <a href={pageUrls.GIVING_TUESDAY_URL}>Giving Tuesday</a>
                      </p>
                      <p>
                        <a onClick={() => setModalShow(true)}>Subscribe</a>
                      </p>
                    </div>
                  </div>

                  <div className="col-md-3 footer-contact">
                    <div className="ml35">
                      <h4>Contact</h4>
                      <p>
                        {data.allContentfulSectionContactInformation.edges[0].node.address2}
                      </p>
                      <p>
                        <a
                          href={`mailto:${data.allContentfulSectionContactInformation.edges[0].node.email}`}
                        >
                          {data.allContentfulSectionContactInformation.edges[0].node.email}
                        </a>
                      </p>
                      <span className="mt40"></span>
                      <a
                        href={
                          data.allContentfulSectionContactInformation.edges[0].node.facebookLink
                        }
                        target="_blank"
                      >
                        <img className="socmed-icons fb-icon" src={fb} alt="" />
                      </a>
                      <a
                        href={data.allContentfulSectionContactInformation.edges[0].node.twitterLink}
                        target="_blank"
                      >
                        <img className="socmed-icons twitter-icon" src={twitter} alt="" />
                      </a>
                      <a
                        href={
                          data.allContentfulSectionContactInformation.edges[0].node.instagramLink
                        }
                        target="_blank"
                      >
                        <img className="socmed-icons ig-icon" src={ig} alt="" />
                      </a>

                      <p className="mt-4">
                        &copy; {new Date().getFullYear()} Glory Reborn Org. <br />
                        All Rights Reserved.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </footer>

          <SubscribeModal
            modalShow={modalShow}
            onHide={() => setModalShow(false)}
            heading={data.allContentfulNewsletterModal.edges[0].node.heading}
            subheading={data.allContentfulNewsletterModal.edges[0].node.subheading.subheading}
            handleSubmit={handleSubmit}
            handleChangeFirstname={handleChangeFirstname}
            handleChangeLastname={handleChangeLastname}
            handleChangeEmail={handleChangeEmail}
          />
          <NotifModal
            modalShow={notifModalShow}
            onHide={() => setNotifModalShow(false)}
            title={data.allContentfulNewsletterModal.edges[0].node.successModalHeading}
            body={
              data.allContentfulNewsletterModal.edges[0].node.successModalBodyText
                .successModalBodyText
            }
          />
        </div>
      )}
    />
  );
};

TemplateWrapper.propTypes = {
  children: PropTypes.func
};

const pageQuery = graphql`
  query LayoutIndexQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulSectionContactInformation {
      edges {
        node {
          address2
          email
          facebookLink
          instagramLink
          twitterLink
        }
      }
    }
    allContentfulNewsletterModal {
      edges {
        node {
          heading
          subheading {
            subheading
          }
          successModalBodyText {
            successModalBodyText
          }
          successModalHeading
        }
      }
    }
  }
`;

export default TemplateWrapper;
