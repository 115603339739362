import React, { useEffect, useRef } from "react";

const XD = (() => {
  let intervalId, lastHash;
  let attachedCallback;

  return {
    postMessage: (message, targetUrl, target) => {
      if (!targetUrl) {
        return;
      }
      target = target || parent;

      if (window.postMessage) {
        target.postMessage(message, targetUrl.replace(/([^:]+:\/\/[^\/]+).*/, '$1'));
      } else {
        target.location = targetUrl.replace(/#.*$/, '') + '#' + Date.now() + '&' + message;
      }
    },

    receiveMessage: (callback, sourceOrigin) => {
      if (window.postMessage) {
        attachedCallback = (e) => {
          if ((typeof sourceOrigin === 'string' && e.origin !== sourceOrigin) ||
              (typeof sourceOrigin === 'function' && sourceOrigin(e.origin) === false)) {
            return false;
          }
          try {
            callback(e);
          } catch (e) {
            console.log(e);
          }
        };

        window.addEventListener('message', attachedCallback, false);
      } else {
        intervalId && clearInterval(intervalId);
        intervalId = null;

        if (callback) {
          intervalId = setInterval(() => {
            const hash = document.location.hash;
            const re = /^#?\d+&/;
            if (hash !== lastHash && re.test(hash)) {
              lastHash = hash;
              callback({ data: hash.replace(re, '') });
            }
          }, 100);
        }
      }
    },
  };
})();

const LGLFormFrame = ({ formId, initialHeight, formPrefix }) => {
  const frameRef = useRef(null);
  const loadCountRef = useRef(0);

  useEffect(() => {
    const createFormFrame = () => {
      const sponsorNowBody = document.getElementById('sponsor-now-body');
      const div = document.createElement('div');
      div.id = `${formId}-top`;
      document.body.appendChild(div);

      const iframe = document.createElement('iframe');
      iframe.id = `frame-${formId}`;
      iframe.height = initialHeight;
      iframe.allowTransparency = 'true';
      iframe.allow = 'payment';
      iframe.frameBorder = '0';
      iframe.scrolling = 'yes';
      iframe.style.width = '100%';
      iframe.style.border = 'none';
      iframe.style.maxHeight = '94vh';
      iframe.style.display = 'none';
      iframe.src = `${formPrefix}/s/${formId}?origin=${encodeURIComponent(window.location.href)}`;

      let existingIframe = sponsorNowBody.querySelector('iframe');
    
      if (existingIframe) {
        // If an iframe exists, remove it
        sponsorNowBody.removeChild(existingIframe);
      }

      document.getElementById('sponsor-now-body').innerHTML = '<div class="modal-form-loader w-100 text-align-center d-flex justify-content-center align-items-center"><div class="lds-heart"><div></div></div></div>';
      document.getElementById('sponsor-now-body').appendChild(iframe);

      // document.body.appendChild(iframe);
      frameRef.current = iframe;
    };

    const setupMessaging = () => {
      const src = `${formPrefix}/s/${formId}?origin=${encodeURIComponent(window.location.href)}`;
      
      if (frameRef.current) {
        frameRef.current.onload = () => {
          loadCountRef.current += 1;
          if (loadCountRef.current > 1) {
            returnToTop();
          }
        };

        try {
          XD.receiveMessage((message) => {
            if (message.data === "returnToTop") {
              returnToTop();
            } else {
              resizeFrame(message.data);
              document.querySelector('.modal-form-loader')?.remove();
              document.getElementById(`frame-${formId}`).style.display = "block";
              document.getElementById(`frame-${formId}`).setAttribute('height', initialHeight);
            }
          }, src.replace(/([^:]+:\/\/[^\/]+).*/, '$1'));
        } catch(ex) {
          console.log(ex);
        }
      }
    };

    const returnToTop = () => {
      document.getElementById(`${formId}-top`).scrollIntoView();
    };

    const resizeFrame = (height) => {
      if (frameRef.current) {
        frameRef.current.height = height;
      }
    };

    createFormFrame();
    setupMessaging();

    return () => {
      if (frameRef.current) {
        frameRef.current.onload = null;
      }
      XD.receiveMessage(null); // Cleanup
    };
  }, [formId, initialHeight, formPrefix]);

  return null; // The component does not render anything directly
};

export default LGLFormFrame;
